/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Verdana';
  src: url('fonts/Verdana.ttf');
}

.font-verdana {
  font-family: 'Verdana', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

.App { /* Base font style */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 15.2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.8500);
  background-color: #f7f7f7;
}

.App .baseFont * { /* Base font style override for UI framework components */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 15.2px;
}

.App .appBg {
  background-color: #f7f7f7;
}

.App .cardBg {
  background-color: white;
}

.App .baseTextBg { /* Base text color as background */
  background-color: rgba(0, 0, 0, 0.8500);
}

.App .secondaryTextColor {
  color: rgba(0, 0, 0, 0.5000);
}

.App .secondaryTextBg {
  background-color: rgba(0, 0, 0, 0.5000);
}

.App .primaryTextColor {
  color: #1c15ff;
}

.App .primaryBg {
  background-color: #1c15ff;
}

.App .highlightTextColor {
  color: #0093d5;
}

.App .highlightBg {
  background-color: #0093d5;
}

@media (display-mode: standalone) {
  .App {
    user-select: none;
  }
}

/* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
.mui-btn {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.mui-btn--primary {
    background-color: #1c15ff;
}
.mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
    background-color: #3444ff;
}
.mui-btn--accent {
    background-color: #0093d5;
}
.mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
    background-color: #069fda;
}
.mui-textfield {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-checkbox, .mui-radio {  /* Reset UI framework styling that affects layout */
    margin-top: 0;
    margin-bottom: 0;
}
.mui-select {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-btn+.mui-btn {  /* Reset UI framework styling that affects layout */
    margin-left: 0;
}
.mui-btn--fab {  /* Reset UI framework styling that breaks compositing */
    z-index: auto;
}

@keyframes fadeIn {
    0%   { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@keyframes slideIn_right {
    0%   { transform: translateX(100%); }
    100% { transform: translateX(0); }
}
@keyframes slideIn_bottom {
    0%   { transform: translateY(100%); }
    100% { transform: translateY(0); }
}

/* System font classes */

.App .systemFontRegular {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}
.App .systemFontBold {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
}
.App .systemFontItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-style: italic;
}
.App .systemFontBoldItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* App-wide text styles specified in React Studio */

.App .actionFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 14.2px;
  font-weight: 400;
}
.App .actionFont * {
  font-size: 14.2px;
}
.App .headlineFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 19.0px;
  font-weight: 600;
}
.App .headlineFont * {
  font-size: 19.0px;
}
.App .navBar {
  background-color: #1c15ff;
  color: black;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  min-height: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 18px;
}

.App .navBar a {
  color: black;
}

.App .navBar .title {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 4px;
}

.App .navBar .backBtn {
  position: absolute;
  padding: 4px;
  margin-left: 14px;
  cursor: pointer;
}

.App .navBar > div {
  margin-top: 7px;
}

@media (min-width: 568px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 768px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 1024px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 320px) {
  .mui-container {
    max-width: 320px;
  }
}
@media (min-width: 568px) {
  .mui-container {
    max-width: 568px;
  }
}
@media (min-width: 768px) {
  .mui-container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .mui-container {
    max-width: 1024px;
  }
}

.AppScreen {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.App .background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App .layoutFlow {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.App .layoutFlow:before {  /* Prevent margin collapse */
  content: "";
  display: table;
  clear: both;
}
.App .layoutFlow .flowRow {
  position: relative;
  width: 100%;
}

.App .screenFgContainer {
  /* Items in the foreground group within a screen are placed with fixed positioning */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.App .foreground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App ul {
  margin: 0;
  padding: 0;
}
.App li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.App .hasNestedComps {
  position: relative;
}
.App .hasNestedComps:after {
  content: "";
  display: table;
  clear: both;
}
.App .hasNestedComps .background {
  position: absolute;
}
.App .hasNestedComps .layoutFlow {
  position: relative;
}
.App .bottomAlignedContent {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App .blockInputOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  z-index: 99999999;
}

.App .marker {
  width: 0px;
  height: 0px;
}

.App .drawer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.App .drawerOpen {
  transform: translateX(0%);
  transition: 0.3s;
}

.App .drawerClosedLeft {
  transform: translateX(-100%);
  transition: 0.3s;
}

.App .drawerClosedRight {
  transform: translateX(100%);
  transition: 0.3s;
}

.App .drawerContent {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

.App .drawerContent > * {
  height: 100%;
}

/* This component has a fixed-size layout */
  .OnDemandPrintListItem {
    min-height: 224px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 434px */
    height: 100%;
  }
  .OnDemandPrintListItem > .background > .containerMinHeight {
    min-height: 224px;
  }


  .OnDemandPrintListItem > .layoutFlow > .elImage {
    position: relative;
    margin-top: 14px;
    width: 136px;
    margin-left: 5.7%;
  }
  .OnDemandPrintListItem > .layoutFlow > .elImage > * { 
  width: 136px;
  height: 77px;
  }
  


  .OnDemandPrintListItem > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 14px;
    margin-left: 5.7%;
    margin-right: 6.0%;
    width: calc(88.3% - 0px);
  }
  .OnDemandPrintListItem > .layoutFlow > .elText > * { 
    width: 100%;
  }
  


  .OnDemandPrintListItem > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 13px;
    margin-left: 5.7%;
    margin-right: 6.0%;
    width: calc(88.3% - 0px);
  }
  .OnDemandPrintListItem > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  

.OnDemandPrintListItem .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.OnDemandPrintListItem .elButton button:focus {
  box-shadow: 0 0 5px rgba(20, 197, 40, 0.5000);
  outline: none;
}



  .OnDemandPrintListItem > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 12px;
    margin-left: 5.7%;
    margin-right: 6.0%;
    width: calc(88.3% - 0px);
  }
  .OnDemandPrintListItem > .layoutFlow > .elButton > * { 
    width: 100%;
  height: 35px;
  }
  


/* Narrowest size for this element's responsive layout */
  .CustomQuoteScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .CustomQuoteScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .CustomQuoteScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .CustomQuoteScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .CustomQuoteScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .CustomQuoteScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .CustomQuoteScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .CustomQuoteScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .CustomQuoteScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .CustomQuoteScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .CustomQuoteScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .CustomQuoteScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .CustomQuoteScreen > .layoutFlow > .elEmbed2 {
    position: relative;
    margin-top: 30px;
    width: 300px;
    margin-left: calc(50.0% - 150px);
  }
  .CustomQuoteScreen > .layoutFlow > .elEmbed2 > * { 
  width: 300px;
  }
  
@media (min-width: 568px) {
  .CustomQuoteScreen > .layoutFlow > .elEmbed2 {
    position: relative;
    margin-top: 30px;
    width: 299px;
    margin-left: calc(50.0% - 150px);
  }
  .CustomQuoteScreen > .layoutFlow > .elEmbed2 > * { 
  width: 299px;
  }
  
}
@media (min-width: 768px) {
  .CustomQuoteScreen > .layoutFlow > .elEmbed2 {
    position: relative;
    margin-top: 30px;
    width: 298px;
    margin-left: calc(50.0% - 149px);
  }
  .CustomQuoteScreen > .layoutFlow > .elEmbed2 > * { 
  width: 298px;
  }
  
}
@media (min-width: 1024px) {
  .CustomQuoteScreen > .layoutFlow > .elEmbed2 {
    position: relative;
    margin-top: 30px;
    width: 298px;
    margin-left: calc(50.5% - 149px);
  }
  .CustomQuoteScreen > .layoutFlow > .elEmbed2 > * { 
  width: 298px;
  }
  
}

.CustomQuoteScreen .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.CustomQuoteScreen .elButton button:focus {
  box-shadow: 0 0 5px rgba(240, 96, 18, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CustomQuoteScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .CustomQuoteScreen > .layoutFlow > .elButton > * { 
    width: 100%;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .CustomQuoteScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .CustomQuoteScreen > .layoutFlow > .elButton > * { 
    width: 100%;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .CustomQuoteScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .CustomQuoteScreen > .layoutFlow > .elButton > * { 
    width: 100%;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .CustomQuoteScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .CustomQuoteScreen > .layoutFlow > .elButton > * { 
    width: 100%;
  height: 35px;
  }
  
}

.CustomQuoteScreen .elFooter {
  overflow: hidden;
}

.CustomQuoteScreen .elFooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .CustomQuoteScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .CustomQuoteScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .CustomQuoteScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 103px;
  }

@media (min-width: 568px) {
  .CustomQuoteScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .CustomQuoteScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .CustomQuoteScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 103px;
  }

}
@media (min-width: 768px) {
  .CustomQuoteScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .CustomQuoteScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .CustomQuoteScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 103px;
  }

}
@media (min-width: 1024px) {
  .CustomQuoteScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .CustomQuoteScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .CustomQuoteScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 103px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CustomQuoteScreen > .screenFgContainer > .foreground > .elCard {
    position: absolute;
    left: 0px;
    right: 0px;
    top: -20px;
    width: 100.0%;
    height: 88px;
  }
@media (min-width: 568px) {
  .CustomQuoteScreen > .screenFgContainer > .foreground > .elCard {
    left: 0px;
    right: 0px;
    top: -20px;
    width: 100.0%;
    height: 88px;
  }
}
@media (min-width: 768px) {
  .CustomQuoteScreen > .screenFgContainer > .foreground > .elCard {
    left: 0px;
    right: 0px;
    top: -20px;
    width: 100.0%;
    height: 88px;
  }
}
@media (min-width: 1024px) {
  .CustomQuoteScreen > .screenFgContainer > .foreground > .elCard {
    left: 0px;
    right: 0px;
    top: -20px;
    width: 100.0%;
    height: 89px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CustomQuoteScreen > .screenFgContainer > .foreground > .elImage {
    position: absolute;
    left: 14px;
    top: 19px;
    width: 104px;
    height: 32px;
  }
@media (min-width: 568px) {
  .CustomQuoteScreen > .screenFgContainer > .foreground > .elImage {
    left: 14px;
    top: 19px;
    width: 104px;
    height: 32px;
  }
}
@media (min-width: 768px) {
  .CustomQuoteScreen > .screenFgContainer > .foreground > .elImage {
    left: 14px;
    top: 19px;
    width: 104px;
    height: 32px;
  }
}
@media (min-width: 1024px) {
  .CustomQuoteScreen > .screenFgContainer > .foreground > .elImage {
    left: 10px;
    top: 9px;
    width: 151px;
    height: 46px;
  }
}

/* This component has a fixed-size layout */
  .Footer {
    min-height: 103px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 285px */
    height: 100%;
  }
  .Footer > .background > .containerMinHeight {
    min-height: 103px;
  }


  .Footer > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 24px;
    width: 259px;
    margin-left: calc(49.8% - 130px);
  }
  .Footer > .layoutFlow > .elText > * { 
  width: 259px;
  }
  


/* Narrowest size for this element's responsive layout */
  .OnDemandPrintsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .OnDemandPrintsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .OnDemandPrintsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .OnDemandPrintsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .OnDemandPrintsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .OnDemandPrintsScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .OnDemandPrintsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .OnDemandPrintsScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .OnDemandPrintsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .OnDemandPrintsScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .OnDemandPrintsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .OnDemandPrintsScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .OnDemandPrintsScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: 44px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .OnDemandPrintsScreen > .layoutFlow > .elPicker > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .OnDemandPrintsScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: 44px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .OnDemandPrintsScreen > .layoutFlow > .elPicker > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .OnDemandPrintsScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: 44px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .OnDemandPrintsScreen > .layoutFlow > .elPicker > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .OnDemandPrintsScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: 44px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .OnDemandPrintsScreen > .layoutFlow > .elPicker > * { 
    width: 100%;
  }
  
}

.OnDemandPrintsScreen .elList li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .OnDemandPrintsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 28px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .OnDemandPrintsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .OnDemandPrintsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 28px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .OnDemandPrintsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .OnDemandPrintsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 28px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .OnDemandPrintsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .OnDemandPrintsScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 42px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .OnDemandPrintsScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}

.OnDemandPrintsScreen .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.OnDemandPrintsScreen .elButton button:focus {
  box-shadow: 0 0 5px rgba(240, 96, 18, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .OnDemandPrintsScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .OnDemandPrintsScreen > .layoutFlow > .elButton > * { 
    width: 100%;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .OnDemandPrintsScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .OnDemandPrintsScreen > .layoutFlow > .elButton > * { 
    width: 100%;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .OnDemandPrintsScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .OnDemandPrintsScreen > .layoutFlow > .elButton > * { 
    width: 100%;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .OnDemandPrintsScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .OnDemandPrintsScreen > .layoutFlow > .elButton > * { 
    width: 100%;
  height: 35px;
  }
  
}

.OnDemandPrintsScreen .elFooter {
  overflow: hidden;
}

.OnDemandPrintsScreen .elFooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .OnDemandPrintsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .OnDemandPrintsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .OnDemandPrintsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 103px;
  }

@media (min-width: 568px) {
  .OnDemandPrintsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .OnDemandPrintsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .OnDemandPrintsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 103px;
  }

}
@media (min-width: 768px) {
  .OnDemandPrintsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .OnDemandPrintsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .OnDemandPrintsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 103px;
  }

}
@media (min-width: 1024px) {
  .OnDemandPrintsScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .OnDemandPrintsScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .OnDemandPrintsScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 103px;
  }

}


/* Narrowest size for this element's responsive layout */
  .OnDemandPrintsScreen > .screenFgContainer > .foreground > .elCard {
    position: absolute;
    left: 0px;
    right: 0px;
    top: -20px;
    width: 100.0%;
    height: 88px;
  }
@media (min-width: 568px) {
  .OnDemandPrintsScreen > .screenFgContainer > .foreground > .elCard {
    left: 0px;
    right: 0px;
    top: -20px;
    width: 100.0%;
    height: 88px;
  }
}
@media (min-width: 768px) {
  .OnDemandPrintsScreen > .screenFgContainer > .foreground > .elCard {
    left: 0px;
    right: 0px;
    top: -20px;
    width: 100.0%;
    height: 88px;
  }
}
@media (min-width: 1024px) {
  .OnDemandPrintsScreen > .screenFgContainer > .foreground > .elCard {
    left: 0px;
    right: 0px;
    top: -20px;
    width: 100.0%;
    height: 89px;
  }
}


/* Narrowest size for this element's responsive layout */
  .OnDemandPrintsScreen > .screenFgContainer > .foreground > .elImage {
    position: absolute;
    left: 14px;
    top: 19px;
    width: 104px;
    height: 32px;
  }
@media (min-width: 568px) {
  .OnDemandPrintsScreen > .screenFgContainer > .foreground > .elImage {
    left: 14px;
    top: 19px;
    width: 104px;
    height: 32px;
  }
}
@media (min-width: 768px) {
  .OnDemandPrintsScreen > .screenFgContainer > .foreground > .elImage {
    left: 14px;
    top: 19px;
    width: 104px;
    height: 32px;
  }
}
@media (min-width: 1024px) {
  .OnDemandPrintsScreen > .screenFgContainer > .foreground > .elImage {
    left: 10px;
    top: 9px;
    width: 151px;
    height: 46px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .HomeScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .HomeScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .HomeScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: 0px;
  }
  .HomeScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elEmbed2 {
    position: relative;
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elEmbed2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elEmbed2 {
    position: relative;
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elEmbed2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elEmbed2 {
    position: relative;
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elEmbed2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elEmbed2 {
    position: relative;
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elEmbed2 > * { 
    width: 100%;
  }
  
}

.HomeScreen .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.HomeScreen .elButton button:focus {
  box-shadow: 0 0 5px rgba(240, 95, 18, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elButton > * { 
    width: 100%;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elButton > * { 
    width: 100%;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elButton > * { 
    width: 100%;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elButton > * { 
    width: 100%;
  height: 35px;
  }
  
}

.HomeScreen .elButtonCopy button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.HomeScreen .elButtonCopy button:focus {
  box-shadow: 0 0 5px rgba(240, 96, 18, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elButtonCopy {
    line-height: 35px;
    position: relative;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elButtonCopy > * { 
    width: 100%;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elButtonCopy {
    line-height: 35px;
    position: relative;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elButtonCopy > * { 
    width: 100%;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elButtonCopy {
    line-height: 35px;
    position: relative;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elButtonCopy > * { 
    width: 100%;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elButtonCopy {
    line-height: 35px;
    position: relative;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elButtonCopy > * { 
    width: 100%;
  height: 35px;
  }
  
}

.HomeScreen .elFooter {
  overflow: hidden;
}

.HomeScreen .elFooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 14px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 103px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 14px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 103px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 14px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 103px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 14px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 103px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elCard {
    position: absolute;
    left: 0px;
    right: 0px;
    top: -20px;
    width: 100.0%;
    height: 88px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elCard {
    left: 0px;
    right: 0px;
    top: -20px;
    width: 100.0%;
    height: 88px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elCard {
    left: 0px;
    right: 0px;
    top: -20px;
    width: 100.0%;
    height: 88px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elCard {
    left: 0px;
    right: 0px;
    top: -20px;
    width: 100.0%;
    height: 89px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elImage {
    position: absolute;
    left: 14px;
    top: 19px;
    width: 104px;
    height: 32px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elImage {
    left: 14px;
    top: 19px;
    width: 104px;
    height: 32px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elImage {
    left: 14px;
    top: 19px;
    width: 104px;
    height: 32px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elImage {
    left: 10px;
    top: 9px;
    width: 151px;
    height: 46px;
  }
}

